<template>
  <Grid>
    <div
      :class="[
        'flex flex-col',
        'col-span-2',
        'sm:col-span-4',
        'md:col-span-6 md:col-start-2',
        'lg:col-span-10 lg:col-start-2',
        containerClass,
      ]"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </Grid>
</template>
<script lang="ts">
import Grid from '~/components/basic/Grid.vue'
export default defineComponent({
  name: 'PageContent',
  components: { Grid },
  inheritAttrs: false,
  props: {
    containerClass: { type: [String, Array, Object], default: '' },
  },
})
</script>
