<template>
  <div
    :class="[
      'grid sm:w-full',
      // max-width
      'max-w-[575px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1272px] ',
      // margins
      'mx-4 sm:mx-auto',
      // cols
      'grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12',
      // gaps
      'gap-x-4 sm:gap-x-5 xl:gap-x-6',
    ]"
  >
    <slot />
  </div>
</template>
<script lang="ts">
export default defineComponent({
  name: 'Grid',
})
</script>
